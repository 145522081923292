import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import Select from "react-select";
import UserDetailModal from "./UserDetailModal";

const SERVER_LINK = process.env.REACT_APP_SERVER_LINK;

const optionValue = [
    { value: 15, label: "15" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
];


const SharkAllUser = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(15); // Default limit set to 10
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [noData, setNoData] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);  // State to track selected user
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        setHasError(false);
        setNoData(false);

        fetch(`${SERVER_LINK}/api/v1/file/GetSharkData`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async (res) => {
                const result = await res.json();
                if (res.ok && result.data && result.data.data.length > 0) {
                    setBrands(result.data.data); // Accessing nested data
                } else if (result.data && result.data.data.length === 0) {
                    setNoData(true);
                } else {
                    setHasError(true);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setHasError(true);
                console.log(err);
            });
    }, [perPage]);



    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = () => {

    }


    const handleViewDetails = (user) => {
        setSelectedUser(user);
        setShowModal(true);  // Open the modal
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);  // Reset selected user when closing modal
    };



    // SVG Icons
    const svg1 = (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z"
                    fill="#000000"
                />
            </g>
        </svg>
    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>User List</Card.Title>
                    <div className="d-flex align-items-center">
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select me-3"
                        />
                    </div>
                </Card.Header>


                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th >
                                    <strong>S.No</strong>
                                </th>
                                <th >
                                    <strong>Name</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Phone / Email</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Nationality</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Gender</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Marrige Status</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Qualification</strong>
                                </th>
                                <th className="d-none">
                                    ID
                                </th>
                                <th className="text-center">
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="4" className="text-center"><Loader /></td>
                                </tr>
                            ) : hasError ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : noData ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            ) : (brands?.map((item, index) => (
                                <tr key={item.PK}>
                                    <td>{index + 1}</td>
                                    <td>{item.full_name}</td>
                                    <td className="text-primary text-center fw-bold">
                                        {item.phone}
                                        {item.email && <div>{item.email}</div>}
                                    </td>
                                    <td className="text-center">{item.Nationality}</td>
                                    <td className="text-center">{item.Gender}</td>
                                    <td className="text-center">{item.marrigeStatus}</td>
                                    <td className="text-center">{item.qualifications}</td>
                                    <td className="d-none">{item.SK}</td>
                                    <td className="text-center">
                                        <Dropdown>
                                            <Dropdown.Toggle onClick={() => handleViewDetails(item)} variant={'success'} className="light sharp i-false">
                                                {svg1}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">Showing <span> 1-4 </span> from <span> {filteredData.length} </span> data </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
            {/* Show Modal with User Details */}
            <UserDetailModal 
                show={showModal} 
                handleClose={handleCloseModal} 
                user={selectedUser} 
            />

        </Col>
    );
}

export default SharkAllUser;
