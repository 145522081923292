import React from 'react';
import { Modal, Button, Tab, Tabs, Card, ListGroup, Row, Col } from 'react-bootstrap';

const UserDetailModal = ({ show, handleClose, user }) => {
    if (!user) return null;

    let address = {};

    // Use try-catch to handle invalid JSON
    try {
        // First parse - user.Address should be a JSON string
        const addressData = JSON.parse(user.Address || '{}');
    
        // Check if the addressData itself contains a nested JSON string
        if (typeof addressData === 'string') {
            // If it is, parse it again
            address = JSON.parse(addressData || '{}');
        } else {
            // If addressData is already an object, use it directly
            address = addressData;
        }
    
        console.log('this is address:', address);
        console.log('this is street data:', address.street);
    
    } catch (error) {
        console.error("Error parsing address JSON:", error);
        address = {}; // Default to an empty object if parsing fails
    }

    return (
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{user.full_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Tabs to separate sections */}
                <Tabs defaultActiveKey="personal" id="user-details-tabs" >
                    {/* Personal Details Tab */}
                    <Tab eventKey="personal" title="Personal Details">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={10}>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Name:</strong>
                                                    <span>{user.full_name}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Phone:</strong>
                                                    <span>{user.phone}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Email:</strong>
                                                    <span>{user.email}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Date of Birth:</strong>
                                                    <span>{user.DOB}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Gender:</strong>
                                                    <span>{user.Gender}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Nationality:</strong>
                                                    <span>{user.Nationality}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Marriage Status:</strong>
                                                    <span>{user.marrigeStatus}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Qualifications:</strong>
                                                    <span>{user.qualifications}</span>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Tab>

                    {/* Business Details Tab */}
                    <Tab eventKey="business" title="Business Details">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={10}>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Business Name:</strong>
                                                    <span>{user.Business_name}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Business Category:</strong>
                                                    <span>{user.Business_Category}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Company Name:</strong>
                                                    <span>{user.Company_name}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Company Address:</strong>
                                                    <span>{user.Company_adress}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Investment Cost:</strong>
                                                    <span>{user.Investment_cost}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Company Income:</strong>
                                                    <span>{user.Company_income}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Company Turnover:</strong>
                                                    <span>{user.Company_turnover}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Working Members:</strong>
                                                    <span>{user.Working_members}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Company Vision:</strong>
                                                    <span>{user.Company_vision}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Company Establishment Date:</strong>
                                                    <span>{user.Company_Establishment}</span>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Tab>

                    {/* Address Details Tab */}
                    <Tab eventKey="address" title="Address Details">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={10}>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Street:</strong>
                                                    <span>{address?.street || 'N/A'}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Landmark:</strong>
                                                    <span>{address?.landmark || 'N/A'}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>State:</strong>
                                                    <span>{address?.state || 'N/A'}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Pin Code:</strong>
                                                    <span>{address?.pinCode || 'N/A'}</span>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="d-flex justify-content-between">
                                                    <strong>Personal Address:</strong>
                                                    <span>{address?.personalAddress || 'N/A'}</span>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserDetailModal;
